.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.side-menu {
  top: 50px;
  z-index: 999;
  direction: ltr;
  background-color: #eee;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.row-red{
  color: red;
}
.row-blue{
  color: blue;
}
.row-black{
  color: black;
}
.row-green{
  color: rgb(18, 196, 18);
  font-weight: bold;
}

 .ant-table-tbody>tr.row-green>td {
  color: green;
  font-weight: 600;
  background-color: #f1f3f5;
}


/* Self css */
/* .loading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
} */
