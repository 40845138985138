.my-collapse-header .ant-collapse-header {
	color: #fff !important;
}

.ant-tabs-tab {
	padding: 15px 0 5px;
	margin-right: 20px;
}

.ant-input-number-input {
	text-align: end !important;
}

.ant-input-disabled, .ant-input[disabled] {
    color: #8a8c8f !important;
}

.ant-input-number-disabled .ant-input-number-input[disabled] {
    color: #8a8c8f !important;
}

.ant-message-notice-content {
	background-color: #A70D2A;
	color: #fff;
}

.text-red {
	color: red;
}

.text-blue {
	color: blue;
}

.text-red-bg-darkkhaki {
	color: red;
	background-color: darkkhaki;
}

.text-red-bg-whitesmoke {
	color: red;
	background-color: whitesmoke;
}

.text-red-bg-white {
	color: red;
	background-color: white;
}

.text-black-bg-darkkhaki {
	color: black;
	background-color: darkkhaki;
}

.text-black-bg-whitesmoke {
	color: black;
	background-color: whitesmoke;
}

.text-black-bg-white {
	color: black;
	background-color: white;
}

.row-selected {
	background: #f4f4f4;
  border-color: rgba(0, 0, 0, 0.03);
}